import {ChangeDetectionStrategy, Component, computed, inject, OnInit, signal} from '@angular/core';
import {ActivatedRoute, CanActivateFn, ResolveFn, Router} from '@angular/router';
import {APIService} from '../../services/api.service';
import {AuthService} from '../../services/authService';
import {IDevDictionaryItem, IDevProfile} from '../../services/api.types';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormsModule} from '@angular/forms';
import {ConfigService} from '../../services/configService';
import {NgSelectComponent} from '@ng-select/ng-select';
import {ToastUIEditorComponent} from '../../../oex-ui-kit/components/toastui-editor/toastui-editor.component';
import {DevContributionComponent} from '../../controls/dev-contribution/dev-contribution.component';
import {AsyncPipe, NgOptimizedImage} from '@angular/common';
import {ValidationDirective} from '../../../oex-ui-kit/components/validation/validation.directive';
import {ModalService} from '../../services/modal.service';
import {ProgressService} from '../../../oex-ui-kit/services/progress.service';
import {AddressService, MergeAddressPipe} from '../../../oex-ui-kit/services/address.service';
import {DevProfileCardComponent} from '../../controls/dev-profile-card/dev-profile-card.component';
import {DeveloperDetailsComponent} from '../../pages/developer-details/developer-details.component';
import {STATUS_NAMES, TileBaseComponent} from '../../controls/tile/tile.base';
import {HttpService} from '../../services/httpService';
import {WarningComponent} from '../warning/warning.component';

interface IDevProfileModel {
  certs: IDevDictionaryItem[];
  skills: IDevDictionaryItem[];
  workTypes: IDevDictionaryItem[];
  profile: IDevProfile
}

const EMPTY_PROFILE: any = {
  location: {},
  published: false,
  needApprove: false,
  iscTechnology: [],
  skills: [],
  certificates: []
}

export const canActivateDevProfileEditor: CanActivateFn = async () => {
  const auth = inject(AuthService);
  return auth.isProfileOwner;
};

const getEmptyProfile = async (auth: AuthService, api: APIService, http: HttpService) => {
  const u = auth.user;
  const p = u ? (await api.getUserInfo(u?.individualKey, u?.name)) : {rating: 0, solutions: 0, certificates: [], dcAcceptedAnswers: 0, dcViews: 0, dcPosts: 0, dcReplies: 0, userImage: '', userCountry: '', dcProfileURL: '', userDescription: '', twitterURL: '', linkedURL: '', awards: 0};

  return {
    ...structuredClone(EMPTY_PROFILE),
    solutions: p.solutions,
    certificates: p.certificates,
    dcPosts: p.dcPosts,
    dcReplies: p.dcReplies,
    dcViews: p.dcViews,
    dcAcceptedAnswers: p.dcAcceptedAnswers,
    userName: u?.name ?? '',
    userIndividualKey: u?.individualKey ?? '',
    rating: p.rating,
    userImage: p.userImage ?? '',
    dcProfileURL: p.dcProfileURL,
    info: p.userDescription ?? '',
    twitterURL: p.twitterURL,
    linkedURL: p.linkedURL,
    awards: p.awards,
    userCompanyNWS: p.userCompanyNWS,
    userCompanyName: p.userCompanyName,
    location: {
      city: '',
      country: p.userCountry ?? '',
      country_code: '',
      text: ''
    }
    // TODO: add company
  } as IDevProfile
};

export const resolveDeveloperProfile: ResolveFn<IDevProfileModel> = async () => {
  const api = inject(APIService);
  const auth = inject(AuthService);
  const id = auth.user?.profileDraft;
  //const id = undefined; //auth.user?.profileDraft;

  const [
    certs,
    skills,
    workTypes,
    profile
  ] = await Promise.all([
    api.getDevCertificates(),
    api.getDevSkills(),
    api.getDevWorkTypes(),
    id === undefined ? getEmptyProfile(auth, api, inject(HttpService)) : api.getDevProfile(id)
  ]);

  if (profile.location) {
    profile.location.text = profile.location.city + ', ' + profile.location.country;
  }
  //profile.certificates = ['Test 1', 'fsdfdsfsdfsd fsdfsdfsd', 'Test 2'];

  return {
    certs,
    skills,
    workTypes,
    profile
  } as IDevProfileModel;
}

@Component({
  selector: 'app-developer-profile',
  templateUrl: './dev-profile-editor.html',
  styleUrls: ['../../controls/tile/status.scss', './dev-profile-editor.scss'],
  imports: [
    FormsModule,
    NgSelectComponent,
    ToastUIEditorComponent,
    DevContributionComponent,
    NgOptimizedImage,
    ValidationDirective,
    AsyncPipe,
    DevProfileCardComponent,
    DeveloperDetailsComponent,
    MergeAddressPipe,
    WarningComponent
  ],
  providers: [AddressService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevProfileEditor implements OnInit {
  protected model = signal<IDevProfileModel | undefined>(undefined);
  protected isPreview = signal(false);
  protected ISC_TECHNOLOGY = ConfigService.PRODUCTS.map(t => t.Name);
  protected status = computed(() => {
    const p = this.model()?.profile;
    const status = {
      code: '',
      text: ''
    }
    if (!p) {
      return status;
    }
    status.code = TileBaseComponent.getStatus(p.published, p.needApprove);
    status.text = STATUS_NAMES[status.code];
    return status;
  });
  protected isUnsaved = signal(false);

  protected addr = inject(AddressService);
  protected router = inject(Router);
  protected auth = inject(AuthService);
  private api = inject(APIService);
  private route = inject(ActivatedRoute);
  private ps = inject(ProgressService);
  private modal = inject(ModalService);
  private data$ = this.route.data.pipe(takeUntilDestroyed());

  ngOnInit() {
    this.data$.subscribe(d => {
      this.model.set(d.model);
    });
  }

  protected async saveClick(doApprove = false) {
    if (!this.validate()) {
      return;
    }

    try {
      await this.save();
      this.isUnsaved.set(false);
      if (doApprove) {
        await this.approve();
      }
    } catch {
    }
  }

  protected previewClick() {
    this.isPreview.set(true);
  }

  protected cancelPreviewClick() {
    this.isPreview.set(false);
  }

  protected sendForApprovalClick() {
    void this.saveClick(true);
  }

  private validate() {
    const p = this.model()?.profile;
    if (!p) {
      return false;
    }
    // Check if linkedURL is exactly linkedin
    if (p.linkedURL && !/^https?:\/\/(www\.)?linkedin\.com/.test(p.linkedURL)) {
      this.modal.show('Please enter valid LinkedIn URL.');
      return false;
    }
    // Check if twitterURL is exactly X
    if (p.twitterURL && !/^https?:\/\/(www\.)?x\.com/.test(p.twitterURL)) {
      this.modal.show('Please enter valid X URL.');
      return false;
    }
    // Check if upworkURL is exactly Upwork
    if (p.upworkURL && !/^https?:\/\/(www\.)?upwork\.com/.test(p.upworkURL)) {
      this.modal.show('Please enter valid Upwork URL.');
      return false;
    }
    const res = (p.workTypeId !== undefined) &&
      p.salaryRate &&
      p.iscTechnology.length &&
      p.info &&
      (p.linkedURL || p.twitterURL || p.upworkURL || p.consultationURL || p.dcdm);
    if (!res) {
      this.modal.show('Please fill all required fields.');
    }
    return res;
  }

  private async save() {
    const profile = this.model()?.profile;
    if (!profile) {
      return;
    }
    // Update location from autocompletion to the actual location object
    /*const loc = profile.location as any;
    if (loc.id) {
      profile.location = {
        country_code: loc.country_code,
        city: loc.city,
        country: loc.country,
      }
    }*/
    this.ps.show()
    try {
      const resp = await this.api.saveDevProfile(profile);
      if (resp?.id !== undefined) {
        profile.id = resp.id;
      }
      if (profile.id !== this.auth.user?.profile) {
        // Now it is draft
        profile.needApprove = false;
        profile.published = false;
        this.model.update(m => structuredClone(m));
      }
      this.updateUserProfileInState(resp.id);
    } catch (e) {
      this.modal.showError(e);
    } finally {
      this.ps.hide();
    }
  }

  private async approve() {
    const profile = this.model()?.profile;
    if (!profile?.id) {
      return;
    }
    this.ps.show()
    try {
      const resp = await this.api.publishDevProfile(profile.id);
      profile.needApprove = true;
      this.model.update(m => structuredClone(m));
    } catch (e) {
      this.modal.showError(e);
    } finally {
      this.ps.hide();
    }
  }

  private updateUserProfileInState(id?: number) {
    if (id === undefined) {
      return;
    }
    const usr = this.auth.user;
    if (!usr) {
      return;
    }
    if (usr.profile === undefined) {
      usr.profile = id;
      usr.isProfileOwner = 1;
    }
    usr.profileDraft = id;
  }

  protected async unpublish() {
    const profile = this.model()?.profile;
    if (!profile?.id) {
      return;
    }
    this.ps.show()
    try {
      await this.api.unpublishDevProfile(profile.id);
      profile.published = false;
      profile.needApprove = false;
      this.model.update(m => structuredClone(m));
    } catch (e) {
      this.modal.showError(e);
    } finally {
      this.ps.hide();
    }
  }

  protected askForDeletion() {
    this.modal.show({
      title: 'Delete',
      message: `Do you really want to delete draft profile?`,
      cancel: true,
      buttons: [
        {text: 'No', cancel: true, close: true},
        {text: 'Yes', default: true, clickOnEnter: true, close: true, click: () => this.deleteProfile()}
      ]
    });
  }

  private async deleteProfile() {
    const profile = this.model()?.profile;
    if (!profile?.id) {
      return;
    }
    this.ps.show()
    try {
      const draftId = profile.id;
      const resp = await this.api.deleteDevProfile(profile.id);
      if (this.auth.user?.profile === this.auth.user?.profileDraft) {
        void this.router.navigateByUrl('/portal');
      } else {
        this.updateUserProfileInState(this.auth.user?.profile);
        this.refreshRoute(draftId);
      }
      /*profile.published = false;
      profile.needApprove = false;
      this.model.update(m => structuredClone(m));*/
    } catch (e) {
      this.modal.showError(e);
    } finally {
      this.ps.hide();
    }
  }

  private refreshRoute(draftId: number) {
    // draftId needed only to cause resolvers to rerun on same route
    void this.router.navigate([], { queryParams: { fromDraft: draftId} });
  }

  protected profileChanged() {
    this.isUnsaved.set(true);
  }
}
